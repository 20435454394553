<template>
  <div class="log-manage">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="预警信号列表" />
    <!-- 搜索框 -->
    <ml-tip class="auto-send" :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <ml-cascader
          prop="conditionVos"
          label="天气类型:"
          :options="weatherTypeData"
          clearable
          collapseTags
          :props="{ label: 'name', value: 'name', children: 'conditions' }"
          placeholder="请选择天气类型"
          v-model="searchData.conditionVos"
          style="margin-bottom: 0"
          @change="searchFn"
        />
        <ml-input
          prop="districtsNameLike"
          label="地区"
          placeholder="请输入地区"
          v-model="searchData.districtsNameLike"
          style="margin-bottom: 0; margin-right: 40px"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
      </ml-form>
      <el-switch
        active-text="自动发送"
        v-model="isAutoSend"
        @change="setAutoSend"
        active-color="#13ce66"
      />
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="预警图标" prop="recordId" width="80px">
          <template #default="scope">
            <el-image
              v-if="scope.row.warningLevelName === '解除预警'"
              style="width: 40px; height: 40px"
              :src="img11B03_6"
            />
            <el-image
              v-else-if="scope.row.icon"
              style="width: 40px; height: 40px"
              :src="scope.row.icon"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="预警编号" prop="publishId" min-width="10%" />
        <el-table-column align="center" label="发布时间" prop="publishDate" min-width="10%" />
        <el-table-column align="center" label="预警类型" prop="categoryCnname" min-width="10%" />
        <el-table-column align="center" label="预警等级" prop="warningLevelName" min-width="10%" />
        <el-table-column
          align="center"
          show-overflow-tooltip
          label="预警标题"
          prop="title"
          min-width="30%"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          label="影响区域"
          prop="district"
          min-width="20%"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          label="地区"
          prop="districtsName"
          min-width="20%"
        />
        <el-table-column align="center" label="预警状态" prop="eventStatus" min-width="10%" />
        <el-table-column align="center" label="发送状态" prop="sendStatus" min-width="10%">
          <template #default="scope">
            <span v-if="scope.row.sendStatus === 'Y'">已发送</span>
            <span v-else>未发送</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" prop="sendStatus" min-width="25%">
          <template #default="scope">
            <el-button type="text" size="mini" @click="openDetails(scope.index, scope.row)">
              详情
            </el-button>
            <el-button
              type="text"
              size="mini"
              v-if="scope.row.sendStatus === 'N'"
              @click="setSendFn(scope.index, scope.row)"
            >
              手动发送
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 详情 -->
    <ml-dialog
      width="800px"
      ref="detailsDialogRef"
      title="预警详情"
      showClose
      :showSubmitBtn="false"
    >
      <template #body>
        <div class="details-box">
          <h1>{{ detailsDialogData.title }}</h1>
          <h3>{{ detailsDialogData.senderCnname }} {{ detailsDialogData.createTime }}</h3>
          <div>
            <el-image
              :style="{ width: '80px', height: '80px', margin: '4px 0 20px' }"
              :src="detailsDialogData.icon"
            />
          </div>
          <div :style="{ textIndent: '25px' }">{{ detailsDialogData.content }}</div>
        </div>
      </template>
    </ml-dialog>
    <!-- 手动发送 -->
    <ml-dialog
      width="800px"
      ref="manuallySendDialogRef"
      title="手动发送预警"
      @click-submit="manuallySendDialogSubmit"
    >
      <template #body>
        <ml-form
          labelWidth="100px"
          style="width: 95%"
          :model="manuallySendDialogForm"
          :rules="manuallySendDialogFormRule"
          ref="manuallySendDialogFormRef"
        >
          <ml-input
            prop="content"
            label="预警内容"
            placeholder="请输入预警内容"
            type="textarea"
            :rows="10"
            v-model="manuallySendDialogForm.content"
          />
        </ml-form>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue'
import { tabberHeight, searchParams, getTabberData, getWeatherType, replacePerCent } from '@/utils'
import { useStore } from 'vuex'
import img11B03_6 from '../../assets/image/11B03_6.png'

export default {
  name: 'EssueSigal',
  setup() {
    const { commit, dispatch } = useStore()
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      conditionVos: [],
      districtsNameLike: ''
    })
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10
    })

    // 获取列表
    const getLogTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData, ['conditionVos'])
      if (searchData.conditionVos.length > 0) {
        searchDataParams.categoryCnname = searchData.conditionVos[0]
        searchDataParams.warningLevelName = searchData.conditionVos[1]
      }
      searchDataParams.districtsNameLike = replacePerCent(searchData.districtsNameLike)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetEventDataRecords',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getLogTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    /* 结束 数据列表 **********************************/
    const getIconObj = row => {
      let key = 'tw'
      const type = row && row.signalType
      // console.log(type)
      if (type === '暴雨') {
        switch (row.signalLevel) {
          case '黄色预警':
            key = 'by'
            break
          case '红色预警':
            key = 'br'
            break
          case '橙色预警':
            key = 'bo'
            break
          case '蓝色预警':
            key = 'bb'
            break
          default:
            break
        }
      } else if (type === '台风') {
        switch (row.signalLevel) {
          case '黄色预警':
            key = 'ty'
            break
          case '红色预警':
            key = 'tr'
            break
          case '橙色预警':
            key = 'to'
            break
          case '蓝色预警':
            key = 'tb'
            break
          default:
            break
        }
      }
      return weatherTypeDataIcon[key]
    }

    // 自动发送
    const isAutoSend = ref(false)
    const setAutoSend = val => {
      const status = val ? 'Y' : 'N'
      dispatch('fetchUpdateEventAlarmStatus', status).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
        }
      })
    }

    // 天气类型
    const weatherTypeData = ref(getWeatherType())
    const weatherTypeDataIcon = {
      br: require(`@/assets/image/baoyu/red.png`),
      bb: require(`@/assets/image/baoyu/blue.png`),
      bo: require(`@/assets/image/baoyu/orange.png`),
      by: require(`@/assets/image/baoyu/yellow.png`),
      tr: require(`@/assets/image/taifeng/red.png`),
      tb: require(`@/assets/image/taifeng/blue.png`),
      to: require(`@/assets/image/taifeng/orange.png`),
      ty: require(`@/assets/image/taifeng/yellow.png`),
      tw: require(`@/assets/image/taifeng/white.png`)
    }

    // 详情
    const detailsDialogRef = ref()
    const detailsDialogData = reactive({
      title: '',
      senderCnname: '',
      createTime: '',
      icon: '',
      content: ''
    })
    const openDetails = (index, row) => {
      detailsDialogData.title = row.title
      detailsDialogData.content = row.content
      detailsDialogData.createTime = row.createTime
      detailsDialogData.senderCnname = row.senderCnname
      detailsDialogData.icon = row.icon
      detailsDialogRef.value.showDialog = true
    }

    // 手动发送
    const manuallySendDialogRef = ref()
    const manuallySendDialogFormRef = ref()
    const manuallySendDialogForm = reactive({
      content: '',
      recordId: ''
    })
    const manuallySendDialogFormRule = {
      content: [{ required: true, message: '请输入内容 ', trigger: 'blur' }]
    }
    const setSendFn = (index, row) => {
      manuallySendDialogForm.content = row.content || ''
      manuallySendDialogForm.recordId = row.recordId || ''
      manuallySendDialogRef.value.showDialog = true
    }
    const manuallySendDialogSubmit = () => {
      manuallySendDialogFormRef.value.CustomFormRef.validate()
        .then(() => {
          const { content, recordId } = manuallySendDialogForm
          dispatch('fetchEventDataRecordSend', { content, recordId }).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
              manuallySendDialogRef.value.showDialog = false
            }
          })
        })
        .catch(() => {})
    }

    onMounted(async () => {
      getLogTabberData()
      // 查询预警事件是否自动发送
      const eventAlarmStatusData = await getTabberData(dispatch, 'fetchGetEventAlarmStatus')
      isAutoSend.value = eventAlarmStatusData && eventAlarmStatusData.data === 'Y' ? true : false
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      getIconObj,
      weatherTypeData,
      weatherTypeDataIcon,
      isAutoSend,
      setAutoSend,
      openDetails,
      detailsDialogRef,
      detailsDialogData,
      manuallySendDialogRef,
      manuallySendDialogForm,
      manuallySendDialogFormRef,
      manuallySendDialogFormRule,
      manuallySendDialogSubmit,
      setSendFn,
      img11B03_6
    }
  }
}
</script>

<style lang="scss">
.log-manage {
  @extend %params-global;
  .auto-send {
    .el-card__body {
      display: flex;
      justify-content: space-between;
    }
  }
}
.details-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}
.descriptions-list {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    min-height: 35px;
    margin-bottom: 20px;
    > div:last-child {
      flex: 1;
      margin-left: 20px;
    }
  }
}
</style>
